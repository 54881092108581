import { ref } from 'vue';
import { Toast } from 'vant';
import 'vant/es/toast/style';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const active = ref('mb_ours');
    const onClickRight = () => Toast('查看更多');
    return {
      onClickLeft,
      active,
      onClickRight,
      banners: {
        // 轮播图
        thumb: [require('../../assets/img/activity1.png'), require('../../assets/img/activity2.png')]
      }
    };
  }
};